<template>
  <div>
    <b-container class="home" fluid style="min-height: 100vh">
      <b-row style="min-height: 100vh">
        <b-col sm="12" lg="7">
          <b-row class="mb-5">
            <b-col class="pt-5">
              <b-row>
                <b-col class="text-center" sm="12" md="12">
                  <img src="@/assets/logo_finpple.png" alt="" style="width: 80%; max-width: 450px">
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4" lg="4"></b-col>
                <b-col md="8" lg="4" class="logo-caption">finanças, simples</b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-5">
            <b-col class="text-center">
              <img src="@/assets/squares.png" alt="" style="width: 100%; max-width: 480px; height: auto;">
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" lg="5"
               style="
               box-shadow: -10px 0 20px 1px #c6c6c6;
                z-index: 1000;
               background: linear-gradient(82.6deg,  #69CB67 0.05%, #579BDA 101.43%);">
          <b-row class="mb-5">
            <b-col xs="12" class="pt-4 text-center">
              <img src="@/assets/em_breve.png" alt="" style="width: 45%; max-width: 450px; height: auto">
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" class="pt-4 d-flex justify-content-center">
              <b-card class="mb-5"
                  style="min-height: 500px; width: 90%; border-radius: 20px; box-shadow: -2px 2px 10px 2px #888888;">
                <p class="text-center">Uma nova forma de aprender o que você sempre quis sobre seu dinheiro.</p>
                <p class="text-center">Com o Finpple, sua educação financeira <b>não precisa mais</b> de:</p>
                <b-row class="mb-5">
                  <b-col sm="4" class="text-center">
                    <img class="thumbs" src="@/assets/no_video.png" alt="">
                    <p style="font-size: 14px; font-weight: bold">Longos Vídeos</p>
                  </b-col>
                  <b-col sm="4" class="text-center">
                    <img src="@/assets/no_book.png" alt="">
                    <p style="font-size: 14px; font-weight: bold">Apostilas</p>
                  </b-col>
                  <b-col sm="4" class="text-center">
                    <img src="@/assets/no_course.png" alt="">
                    <p style="font-size: 14px; font-weight: bold">Cursos Pagos</p>
                  </b-col>
                </b-row>
                <b-row v-if="form_sent == false">
                  <b-col class="mb-4" sm="12" md="12">
                    <p class="text-center">Fique atualizado do nosso lançamento, deixe seu email abaixo.</p>
                  </b-col>
                  <b-col sm="2"></b-col>
                  <b-col sm="8">
                    <b-form @submit="submit">
                      <b-form-group
                          class="mb-5"
                          id="input-group-2">
                        <b-form-input
                            id="input-2"
                            v-model="form.name"
                            placeholder="Primeiro nome (opcional)"
                            required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                          class="mb-5"
                          id="input-group-1"
                      >
                        <b-form-input
                            id="input-1"
                            v-model="form.email"
                            type="email"
                            placeholder="Seu email"
                            required
                        ></b-form-input>
                        <p class="mt-1 text-muted" style="font-size: 11px">Nunca compartilharemos seu email com ninguém</p>
                      </b-form-group>
                      <b-row>
                        <b-col class="text-center" lg="12" sm="12">
                          <b-button
                              class="px-5 py-3"
                              type="submit"
                              pill
                              style="background: #009AFF; border: none">Avise-me!
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </b-col>
                </b-row>
                <b-row v-else>
                  <b-col sm="2"></b-col>
                  <b-col sm="8" class="text-center"  style="height: 180px">
                    <div v-if="loading == true" class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    <div v-else>
                      <h6 class="mb-4"><b>Combinado!</b></h6>
                      <p>Em pouco tempo avisaremos você sobre o lançamento do Finpple.</p>
                      <img src="@/assets/rocket2.png" alt="" style="width: 30%">
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
    </b-container>
  </div>

</template>

<script>
/* eslint-disable */
import axios from 'axios'

export default {
  name: 'Home',
  data () {
    return {
      form: {
        email: '',
        name: ''
      },
      form_sent: false,
      loading: false
    }
  },
  components: {},
  methods: {
    submit (evt) {
      this.loading = true
      this.form_sent = true
      let data = JSON.stringify({
        'list_ids': [
          '409fa0d3-cdb1-468c-8e99-d0a5a933b012'
        ],
        'contacts': [
          {
            'email': this.form.email,
            'first_name': this.form.name,
            'last_name': 'string (optional)',
            'postal_code': 'string (optional)',
            'state_province_region': 'string (optional)',
            'custom_fields': {}
          }
        ]
      })
      evt.preventDefault()
      axios.put('https://api.sendgrid.com/v3/marketing/contacts', data,
          {
            headers: {
              'Authorization': 'Bearer ' + process.env.VUE_APP_EMAIL_TOKEN,
              'content-type': 'application/json'
            }
          })
          .then(response => {
            this.loading = false
            console.log(response)
            if(response.statusText == 'Accepted') {
              this.form.name = ''
              this.form.email = ''
            }
          })
          .catch(e => {
            console.log(e)
          })
    }
  }
}
</script>

<style lang="scss">

.logo-caption {
  font-style: italic;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.5);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="text"],
input[type="email"] {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

input[type="text"]:focus,
input[type="email"]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: linear-gradient(219.16deg, #5D94C2 28.21%, #1B3A57 114.61%);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@media only screen and (max-width: 768px) {
  .logo-caption {
    text-align: center;
    letter-spacing: 0;
    font-size: 18px;
  }

  .thumbs {
    width: 80%;
    max-width: 100px
  }
}

</style>
